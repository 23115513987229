/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import omit from 'lodash/omit';
import get from 'lodash/get';
import InstrumentSlider from './components/InstrumentSlider';
import InstrumentSliderMobile from './components/InstrumentSliderMobile';
import capexlogo from './images/capex-logo.svg';
 
function App() {
  
  const queryUrl = new URLSearchParams(window.location.search);
  const instrumentsKeysFromURL = () => {
    const arrayOfInstruments = queryUrl.get('instruments').toLowerCase().split(',');
    const keysMapping = {
      cardano: 'adausd',
    };
    const finalList = arrayOfInstruments.map(instrument => {
      return get(keysMapping, instrument) ? get(keysMapping, instrument) : instrument;
    });
    return finalList.join(',');
  };
  const query = {
    instruments: instrumentsKeysFromURL(),
    width: queryUrl.get('width'),
    height: queryUrl.get('height'),
    theme: queryUrl.get('theme'),
  };
  const marketingParamsFromURL = omit(qs.parse(window.location.search, { ignoreQueryPrefix: true }), ['instruments', 'width', 'height', 'theme']);
  const defaultParams = {
    ...queryUrl.get('company') && { company: queryUrl.get('company') }
  };
  const marketingParamsForRedirect = {
    ...defaultParams,
    ...marketingParamsFromURL
  };
  const platformUrlWithMarketingParams = window.location.hash!==""?window.location.hash.replace('#',''):`https://capex.com/registration${qs.stringify(marketingParamsForRedirect,{ addQueryPrefix: true })}`;
  const [items, setItems] = useState([]);
  const theme = {
    black: {
      bgColor: '#161212'
    },
    blue: {
      bgColor: '#0995dd'
    },
    red: {
      bgColor: '#ff4848'
    },
    default: {
      bgColor: '#ff4848'
    }
  }
 
  console.log(get(theme, query.theme, get(theme, 'default')));
  useEffect(() => {
    const fetchItems = async () => {
      const result = await axios('https://api-v2.capex.com/quotesv2?key=1&q='+query.instruments);
      setItems(result.data);
    };
    fetchItems();
    const intervalId = setInterval(async () => {
      fetchItems();
    }, 2000);

  return () => clearInterval(intervalId); //This is important
  }, []);
  
 

    return (
      <>
      <div className="desktop-widget">
        { query.height !== '90' &&
          <header style={{ backgroundColor: get(theme,query.theme,get(theme,'default')).bgColor }}>
              <a href={platformUrlWithMarketingParams} className="platform-url" target="blank"></a>
              <div className="container">
                <div className="logo">
                  <img src={ capexlogo } alt="CAPEX.com Logo" />
                </div>
            </div>
            </header>
        }
        <main className={( query.height === "90" ? "height-small" : "height-big" )}>
          <div className="container">
              <div className="instrument-list">
              { query.height === '90' &&
                <div className="instrument-card logo-card">
                  <div className="instrument-card-inner" style={{ backgroundColor: get(theme,query.theme,get(theme,'default')).bgColor }}>
                    <a href={platformUrlWithMarketingParams} className="platform-url" target="blank"></a>
                    <div className="logo">
                      <img src={ capexlogo } alt="CAPEX.com Logo" />
                    </div>
                  </div>
                </div>
              }
                <InstrumentSlider items={items} instrumentHeight={query.height} redirectURL={platformUrlWithMarketingParams}/>
               
              { query.height === '90' &&
                <div className="instrument-card link-card">
                  <div className="instrument-card-inner">
                    <a href={platformUrlWithMarketingParams} className="platform-url" target="blank"></a>
                    <div className="link-text">
                        <span>Start  Trading</span>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </main>
      </div>

      <div className="mobile-widget">
          <header style={{ backgroundColor: get(theme,query.theme,get(theme,'default')).bgColor }}>
          <a href={platformUrlWithMarketingParams} className="platform-url" target="blank"></a>
              <div className="container">
                <div className="logo">
                  <img src={ capexlogo } alt="CAPEX.com Logo" />
                </div>
            </div>
          </header>  
          <main>
            <div className="slide-container">
                <div className="instrument-list">
                <InstrumentSliderMobile items={items} instrumentHeight={query.height} redirectURL={platformUrlWithMarketingParams}/>
              </div>
            </div>
          </main>
      </div>
      </>
    )
}
 
export default App;