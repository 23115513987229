/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import greenArrow from '../images/green-arrow.png';
import redArrow from '../images/red-arrow.png';

function InstrumentCard(props) {
  const [oldData, setOldData] = useState();
  const [newData, setNewData] = useState();
  const [className, setClassName] = useState('');

  useEffect(() => {
    setNewData(props.instrument.price);
  }, [props.instrument.price]);

  useEffect(() => {
    setOldData(newData);
  }, [props.instrument.price]);

  useEffect(() => {
    if (parseFloat(newData) > parseFloat(oldData)) {
      setClassName('green');
    } else if (parseFloat(newData) < parseFloat(oldData)) {
      setClassName('red');
    }
    setTimeout(async () => {
      setClassName('');
    }, 1500);
  }, [props.instrument.price]);

  return (
    <div className="instrument-card" key={props.instrument.id}>
      <div className="instrument-card-inner">
      <a href={props.redirectURL} className="platform-url" target="blank"></a>
        <div className="card-top">
          <div className="card-top-left">
            <p>
              <small>{props.instrument.display}</small>
            </p>
            <p className={className}>{newData}</p>
          </div>
          <div className="card-top-right">
            <div className="arrow-wrapper">
              <img src={parseFloat(props.instrument.change) < 0 ? redArrow : greenArrow} alt="Arrow Icon" />
            </div>
            <p style={{ color: parseFloat(props.instrument.change) < 0 ? '#ff4848' : '#58c914' }}>
              {props.instrument.change}
            </p>
          </div>
        </div>
       
        <div className="card-bottom">
          <div className="card-buttons">
            <a href={props.redirectURL} className="card-button platform-url arrow-right" target="blank">
              <span>Buy</span>
            </a>
            <a href={props.redirectURL} className="card-button platform-url arrow-right" style={{ backgroundColor: '#ff4848'}} target="blank">
              <span>Sell</span>
            </a>
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default InstrumentCard;
