import React from 'react'
import InstrumentCard from './InstrumentCard';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



function InstrumentSlider ({items, instrumentHeight, redirectURL}) {
   
    var settings = {
        dots: false,
        infinite: Object.values(items).length > 4,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 4000,
        draggable: true
      };
   
    return (
        <div className="instrument-list-slider">
            <Slider {...settings}>
                {
                    Object.values(items).map(item =>{
                        return (
                            <InstrumentCard key={item.id} instrument={item} instrumentHeight={instrumentHeight} redirectURL={redirectURL}/>
                        )
                    })   
                }
            </Slider>
        </div>
    )
}
   
   

export default InstrumentSlider