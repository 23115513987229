import React from 'react'
import InstrumentCard from './InstrumentCard';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



function InstrumentSliderMobile ({items, instrumentHeight, redirectURL}) {
   
    var settings = {
        dots: false,
        speed: 500,
        initialSlide: 0,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        infinite: Object.values(items).length > 4,
      };
   

        var content = Object.values(items);
        var groupSize = 2;
        var rows = content.map(function(item) {
            // map content to html elements
            return <div key={item.id} className="slide-col">
             <InstrumentCard  instrument={item} instrumentHeight={instrumentHeight} redirectURL={redirectURL}/>
            </div>;
        }).reduce(function(r, element, index) {
            // create element groups with size 3, result looks like:
            // [[elem1, elem2, elem3], [elem4, elem5, elem6], ...]
            index % groupSize === 0 && r.push([]);
            r[r.length - 1].push(element);
            return r;
        }, []).map(function(rowContent, index) {
            // surround every group with 'row'
            return <div key={index}  className="slide-row"><div key={index}  className="slide-row-inner">{rowContent}</div></div>;
        });
        return (
            <div className="container">
            <Slider {...settings}>
                {rows}
            </Slider>
            </div>
        )
    
}
   
   

export default InstrumentSliderMobile